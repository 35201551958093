import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full text-xs text-gray-400 whitespace-nowrap truncate"
}
const _hoisted_2 = {
  key: 1,
  class: "w-full text-xs text-gray-400 whitespace-nowrap truncate"
}
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    ref: "tableCellContainer",
    class: ["relative px-3 py-3 lg:flex flex-col text-sm text-left truncate", _ctx.dynamicClass]
  }, [
    _createVNode("div", {
      class: "overflow-x-hidden",
      onMouseenter: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleMouseEnterEvent && _ctx.handleMouseEnterEvent(...args))),
      onMouseleave: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleMouseLeaveEvent && _ctx.handleMouseLeaveEvent(...args)))
    }, [
      (_ctx.prefix)
        ? (_openBlock(), _createBlock("p", _hoisted_1, _toDisplayString(_ctx.prefix), 1))
        : _createCommentVNode("", true),
      _createVNode("p", {
        ref: "textContainer",
        class: "w-full whitespace-nowrap truncate"
      }, _toDisplayString(_ctx.text), 513),
      (_ctx.suffix)
        ? (_openBlock(), _createBlock("p", _hoisted_2))
        : _createCommentVNode("", true),
      (_ctx.property == 'schoolAILinkageStatus' && _ctx.text != '未連携')
        ? (_openBlock(), _createBlock("div", _hoisted_3, [
            _createVNode("button", {
              class: "text-xs text-blue py-1/2 px-2 rounded border border-black hover:px-4 hover:font-bold hover:bg-white",
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.openSchoolAI()), ["prevent"]))
            }, " ログを確認する ")
          ]))
        : _createCommentVNode("", true)
    ], 32),
    (_ctx.hover && _ctx.isHovered && _ctx.isOverFlowing)
      ? (_openBlock(), _createBlock("div", {
          key: 0,
          class: "bg-white p-2 break-all z-10 shadow-lg rounded absolute",
          onMouseenter: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleMouseEnterEvent && _ctx.handleMouseEnterEvent(...args))),
          onMouseleave: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleMouseLeaveEvent && _ctx.handleMouseLeaveEvent(...args)))
        }, _toDisplayString(_ctx.text), 33))
      : _createCommentVNode("", true),
    (_ctx.property == 'qandaLinkageStatus' && _ctx.text == '連携済み')
      ? (_openBlock(), _createBlock("div", _hoisted_4, [
          _createVNode("button", {
            class: "text-xs text-blue py-1/2 px-2 rounded border border-black hover:px-4 hover:font-bold hover:bg-white",
            onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.showQandaLog()), ["prevent"]))
          }, " ログを確認する ")
        ]))
      : _createCommentVNode("", true)
  ], 2))
}